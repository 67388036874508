@import "src/app/theme.module";


#businessUnit-detail {
  padding: 2rem;

}

.cursor-pointer {
  cursor: pointer;
}
